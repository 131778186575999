export default {

    REPORT: '统计报表',

    OrderManagerSubheader: '订单相关',
    OrderManager: '订单信息',
    orderAll: '所有订单',
    orderWait: '待分配',
    orderAllocated: '已分配',
    orderTimeout: '过期未分配',

    WehcatManager: '微信相关',
    wechatMessage: '微信留言',

    ComplainManager: '投诉建议',
    complainAll: '所有投诉',

    SystemSetting: '系统设置',
    SystemSettingParam: '系统参数',

    users: '用户管理',
    PassengerManager: '乘客信息',
    passengerAll: '所有乘客',
    passengerBlacklist: '黑名单',
    passengerReallist: '实名认证',
    DriverManager: '司机信息',
    driverRealAuth: '实名认证',
    driverAll: '司机列表',
    driverVehicle: '车辆列表',
    UserWechatManager: '微信相关',
    userWechatList: '微信用户',
    UserCompanyManager: '单位信息',
    userCompanyList: '出租汽车公司',

    CRM: '总览',

    // 短信维护
    SmsMaintain: '短信维护',
    // 短信模板
    smsTemplate: '短信模板',
    // 已发短信
    smsSendSuccess: '已发短信',
    // 待发短信
    smsSendFail: '待发短信',
    // 内容维护
    ContentMaintain: '内容维护',
    // 关于我们
    aboutUs: '关于我们',
    // 法律条款
    law: '法律条款',
    // 平台规则
    rule: '平台规则',
    // 常见问题
    question: '常见问题',
    // 用户协议
    agreement: '用户协议',
    // FLASH设置
    // 微信回复设置
    // 关注推送
    // 留言自动回复


    //  人员相关
    //  新增注册日报
    //  黑名单统计
    //  订单相关
    //  订单日报
    //  时段分布
    //  司机抢单统计


    //  管理员维护
    //  管理员账户
    //  角色维护
    //  修改密码
    //  网站配置
    //  系统配置
    //  代码维护
    //  微信菜单
    report: '报表管理',
    system: '系统管理',
    content: '内容维护',
    systemUser: '用户管理',
    systemRole: '角色查看',
    systemPrivilege: '权限查看',
    Dashboards: '看板',
    Consumer: '用户',
    'Paid Users': '付费用户',
    'Active Users': '活跃用户',
    'Pending Users': '未确认用户',
    'Consumer List': '用户列表',
    'Account List': '用户账户',
    'Assets List': '用户资产',
    'Order': '订单',
    'Order Rent List': '租赁订单',
    'Order Sell List': '销售订单',
    SystemManager: '系统管理',
    AssetsManager: '资产管理',
    Analytics: '分析',
    eCommerce: '电商',
    Calendar: '日历',
    Chat: '聊天',
    Email: '邮件',
    Invoice: '发票',
    List: '列表',
    BikeBattery: '电池',
    StoreRentChannel: '租赁方式',
    BikeFrame: '车架',
    BikeWhole: '整车',
    Preview: '预览',
    Edit: '编辑',
    Add: '添加',
    User: '用户',
    AreaCard: '特权卡',
    RideCard: '骑行卡',
    BatteryCard: '换电卡',
    CardArea: '特权卡',
    CardRide: '骑行卡',
    CardBattery: '换电卡',
    'User List': '用户列表',
    'User View': '用户视图',
    'Company': '总公司',
    Pages: '页面',
    Authentication: '认证',
    'Login V1': '登录1',
    'Login V2': '登录2',
    'Register V1': '注册1',
    'Register V2': '注册2',
    'Forgot Password V1': '忘记密码1',
    'Forgot Password V2': '忘记密码2',
    'Reset Password V1': '重置密码2',
    'Reset Password V2': '重置密码2',
    Miscellaneous: '杂项',
    'Under Maintenance': '维护中',
    Error: '错误',
    'Coming Soon': '即将开放',
    'Not Authorized': '为人质',
    'Knowledge Base': '知识库',
    'Account Settings': '账户设置',
    Pricing: '价格',
    FAQ: 'FAQ',
    AreaView: '区域总店信息',
    Area: '区域',
    StoreManage: '体验馆管理',
    StoreArea: '区域总店管理',
    StoreStore: '体验馆管理',
    'Area Store': '区域总店',
    Typography: '段落',
    Icons: '图标',
    Gamification: '游戏',
    Cards: '卡片',
    Basic: '基本',
    Statistics: '统计',
    Advance: '进阶',
    Actions: '操作',
    Chart: '图表',
    Components: '组件',
    Alert: '提醒',
    Avatar: '头像',
    Badge: 'Badge',
    Button: '按键',
    Menu: '菜单',
    'Expansion Panel': '扩展面板',
    Dialog: '窗口',
    Pagination: '分页',
    Tabs: 'Tabs',
    Tooltip: '提示工具',
    Chip: 'Chip',
    Snackbar: 'Snackbar',
    Stepper: '步进器',
    Timeline: '时间线',
    Treeview: '树视图',
    'Store': '体验馆',
    'Forms Elements': '表单元素',
    Autocomplete: '自动完成',
    Checkbox: '复选框',
    Combobox: '组合框',
    'File Input': '文件输入',
    Radio: '单选框',
    'Range Slider': '范围滑块',
    Select: '选择框',
    Slider: '滑块',
    Switch: '开关',
    Textarea: '富文本',
    Textfield: '文本输入',
    'Date Picker': '日期选择器',
    'Month Picker': '月选择器',
    'Time Picker': '时间选择器',
    Rating: '评分',
    'Form Layout': '表单布局',
    'Form validation': '表单验证',
    Tables: '表格',
    'Simple Table': '简单表格',
    Datatable: '数据表格',
    'CHARTS AND MAPS': '图表和地图',
    Charts: '图表',
    'Apex Chart': 'Apex Chart',
    Chartjs: 'Chartjs',
    'Leaflet Maps': 'Leaflet Maps',
    'ADD EVENT': '添加事件',
    Others: '其他',
    'Menu Levels': '菜单层级',
    'Menu Level 2.1': '菜单 2.1',
    'Menu Level 2.2': '菜单 2.2',
    'Menu Level 3.1': '菜单 3.1',
    'Menu Level 3.2': '菜单 3.2',
    'Disabled Menu': '禁用',
    'Raise Support': '支持',
    Documentation: '文档',
    'Charts & Maps': '图表 & 地图',
    Apps: 'Apps',
    Ai: 'AI助手',
}
