const users = [

    // 乘客
    {
        path: '/apps/users/passenger/list',
        name: 'apps-users-passenger-list',
        component: () => import('@/views/apps/users/passenger/passenger-list/Passenger.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/apps/users/blacklist/list',
        name: 'apps-users-blacklist-list',
        component: () => import('@/views/apps/users/passenger/blacklist/Blacklist.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/apps/users/real/list',
        name: 'apps-users-real-auth',
        component: () => import('@/views/apps/users/passenger/real-auth/RealAuth.vue'),
        meta: {
            layout: 'content',
        },
    },

    // 司机
    {
        path: '/apps/users/driver/list',
        name: 'apps-users-driver-list',
        component: () => import('@/views/apps/users/driver/driver-list/Driver.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/apps/users/vehicle/list',
        name: 'apps-users-vehicle-list',
        component: () => import('@/views/apps/users/driver/vehicle-list/Vehicle.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/apps/users/driver/real/list',
        name: 'apps-users-driver-real-auth',
        component: () => import('@/views/apps/users/driver/real-auth/RealAuth.vue'),
        meta: {
            layout: 'content',
        },
    },

    // 微信相关
    {
        path: '/apps/users/wechat/user',
        name: 'apps-users-wechat-user',
        component: () => import('@/views/apps/users/wechat/user-list/User.vue'),
        meta: {
            layout: 'content',
        },
    },

    // 单位
    {
        path: '/apps/users/company/list',
        name: 'apps-users-company-list',
        component: () => import('@/views/apps/users/company/company-list/Company.vue'),
        meta: {
            layout: 'content',
        },
    },

]

export default users
