const contents = [

    // 短信维护
    {
        path: '/content/sms/template',
        name: 'content-sms-template',
        component: () => import('@/views/apps/content/sms/template/ContentSmsTemplate.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/content/sms/send/success',
        name: 'content-sms-send-success',
        component: () => import('@/views/apps/content/sms/send-success/ContentSmsSendSuccess.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/content/sms/send/fail',
        name: 'content-sms-send-fail',
        component: () => import('@/views/apps/content/sms/send-fail/ContentSmsSendFail.vue'),
        meta: {
            layout: 'content',
        },
    },

    // 内容维护
    {
        path: '/content/about-us',
        name: 'content-about-us',
        component: () => import('@/views/apps/content/content/about-us/ContentAboutUs.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/content/law',
        name: 'content-law',
        component: () => import('@/views/apps/content/content/law/ContentLaw.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/content/rule',
        name: 'content-rule',
        component: () => import('@/views/apps/content/content/rule/ContentRule.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/content/question',
        name: 'content-question',
        component: () => import('@/views/apps/content/content/question/ContentQuestion.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/content/question',
        name: 'content-question',
        component: () => import('@/views/apps/content/content/question/ContentQuestion.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/content/agreement',
        name: 'content-agreement',
        component: () => import('@/views/apps/content/content/agreement/ContentAgreement.vue'),
        meta: {
            layout: 'content',
        },
    },
]

export default contents
