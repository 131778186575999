import {
    mdiAccountGroup,
    mdiCardAccountDetailsOutline,
    mdiWechat,
    mdiDomain,
} from '@mdi/js'

export default [
    {
        subheader: 'users',
    },
    {
        title: 'PassengerManager',
        icon: mdiAccountGroup,
        children: [
            {
                title: 'passengerAll',
                to: { name: 'apps-users-passenger-list', params: { id: 21 } },
            },
            {
                title: 'passengerBlacklist',
                to: { name: 'apps-users-blacklist-list', params: { id: 21 } },
            },
            {
                title: 'passengerReallist',
                to: { name: 'apps-users-real-auth', params: { id: 21 } },
            },
        ],
    },
    {
        title: 'DriverManager',
        icon: mdiCardAccountDetailsOutline,
        children: [
            {
                title: 'driverAll',
                to: { name: 'apps-users-driver-list', params: { id: 21 } },
            },
            {
                title: 'driverVehicle',
                to: { name: 'apps-users-vehicle-list', params: { id: 21 } },
            },
            {
                title: 'driverRealAuth',
                to: { name: 'apps-users-driver-real-auth', params: { id: 21 } },
            },
        ],
    },
    {
        title: 'UserWechatManager',
        icon: mdiWechat,
        children: [
            {
                title: 'userWechatList',
                to: { name: 'apps-users-wechat-user', params: { id: 21 } },
            },
        ],
    },
    {
        title: 'UserCompanyManager',
        icon: mdiDomain,
        children: [
            {
                title: 'userCompanyList',
                to: { name: 'apps-users-company-list', params: { id: 21 } },
            },
        ],
    },
]
