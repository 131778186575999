// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiMessageProcessing, mdiContentSaveOutline, mdiLifebuoy, mdiMenu, mdiShieldOutline } from '@mdi/js'

export default [
    {
        subheader: 'content',
    },
    {
        title: 'SmsMaintain',
        icon: mdiMessageProcessing,
        children: [
            {
                title: 'smsTemplate',
                to: { name: 'content-sms-template', params: { id: 21 } },
            },
            {
                title: 'smsSendSuccess',
                to: { name: 'content-sms-send-success', params: { id: 21 } },
            },
            {
                title: 'smsSendFail',
                to: { name: 'content-sms-send-fail', params: { id: 21 } },
            },
        ],
    },
    {
        title: 'ContentMaintain',
        icon: mdiContentSaveOutline,
        children: [
            {
                title: 'aboutUs',
                to: { name: 'content-about-us', params: { id: 21 } },
            },
            {
                title: 'law',
                to: { name: 'content-law', params: { id: 21 } },
            },
            {
                title: 'rule',
                to: { name: 'content-rule', params: { id: 21 } },
            },
            {
                title: 'question',
                to: { name: 'content-question', params: { id: 21 } },
            },
            {
                title: 'agreement',
                to: { name: 'content-agreement', params: { id: 21 } },
            },
        ],
    },
]
