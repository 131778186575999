<template>
  <div>
    <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
      <template v-slot:activator="{ on, attrs }">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px" v-bind="attrs" color="primary" class="v-avatar-light-bg primary--text" v-on="on">
            <v-img v-if="userData.avatar" :src="userData.avatar"></v-img>
            <v-icon v-else color="primary" size="28">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
            <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
              <v-img v-if="userData.avatar" src="@/assets/images/avatars/1.png"></v-img>
              <v-icon v-else color="primary" size="28">
                {{ icons.mdiAccountOutline }}
              </v-icon>
            </v-avatar>
          </v-badge>
          <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align:middle">
            <span class="text--primary font-weight-semibold mb-n1">
              {{ userData.fullName || userData.username }}
            </span>
            <small class="text--disabled text-capitalize">{{ userData.role }}</small>
          </div>
        </div>

        <v-divider></v-divider>

        <v-list-item @click="toUpdatePwd">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiFormTextboxPassword }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>修改密码</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logoutUser">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>退出登录</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="dialog.open" persistent max-width="320px">
      <v-card>
        <v-card-title>
          <span class="headline">修改密码</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="dialog.content.pre" label="原密码" dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="dialog.content.new" label="新密码" dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="dialog.content.newAgain" label="确认新密码" dense></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" outlined @click="dialog.open = false"> 取消 </v-btn>
          <v-btn color="warning" @click="updatePwd()"> 提交 </v-btn> </v-card-actions> </v-card>
    </v-dialog>
    <v-snackbar v-model="snak.open" multi-line :light="$vuetify.theme.dark" :timeout="2000">
      {{ snak.msg }}
      <template #action="{ attrs }">
        <v-btn color="error" text v-bind="attrs" @click="snak.open = false"> 关闭 </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiFormTextboxPassword,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import { initialAbility } from '@/plugins/acl/config'
import { getCurrentInstance } from '@vue/composition-api'
import axios from '@axios'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const userData = JSON.parse(localStorage.getItem('userData'))

    const logoutUser = () => {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')
      vm.$ability.update(initialAbility)
      router.push({ name: 'auth-login' })
    }

    const snak = ref({ open: false, msg: '' })

    const dialog = ref({
      open: false,
      loading: false,
      content: { pre: '', new: '', newAgain: '' }
    })

    const toUpdatePwd = () => {
      dialog.value = {
        open: true,
        loading: false,
        content: { pre: '', new: '', newAgain: '' }
      }
    }

    const updatePwd = () => {
      const data = { ...dialog.value.content }
      if (data.new != data.newAgain) {
        snak.value = { open: true, msg: `新密码两次输入不一致` }
        return
      }
      axios.post('/admin-company/v1/system/user/update-pwd', data).then(res => {
        const { code, data, msg } = res.data
        if (code !== 200) {
          snak.value = { open: true, msg: `错误码：${code}, ${msg}` }
          return
        }
        snak.value = { open: true, msg: `修改成功` }
        dialog.open = false
      })
    }

    return {
      logoutUser,
      userData, snak,
      dialog, toUpdatePwd, updatePwd,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiFormTextboxPassword
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
