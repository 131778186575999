const systems = [
    // System 系统管理
    {
        path: '/apps/system/user',
        name: 'apps-system-user',
        component: () => import('@/views/apps/system/user-list/User.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/apps/system/role',
        name: 'apps-system-role',
        component: () => import('@/views/apps/system/role-list/Role.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/apps/system/privilege',
        name: 'apps-system-privilege',
        component: () => import('@/views/apps/system/privilege-list/Privilege.vue'),
        meta: {
            layout: 'content',
        },
    },
    
    {
        path: '/apps/system/setting',
        name: 'apps-system-setting',
        component: () => import('@/views/apps/system/setting-list/Setting.vue'),
        meta: {
            layout: 'content',
        },
    },
]

export default systems
