import { mdiReorderHorizontal } from '@mdi/js'
import { mdiFaceAgent } from '@mdi/js'

export default [
    {
        subheader: 'OrderManagerSubheader',
    },
    {
        title: 'OrderManager',
        icon: mdiReorderHorizontal,
        children: [
            {
                title: 'orderAll',
                to: { name: 'apps-orders-order-all', params: { id: 21 } },
            },
            {
                title: 'orderWait',
                to: { name: 'apps-orders-order-wait', params: { id: 21 } },
            },
            {
                title: 'orderAllocated',
                to: { name: 'apps-orders-order-allocated', params: { id: 21 } },
            },
            {
                title: 'orderTimeout',
                to: { name: 'apps-orders-order-timeout', params: { id: 21 } },
            },
        ],
    },
    //   {
    //       title: 'WehcatManager',
    //       icon: mdiAccountOutline,
    //       children: [
    //           {
    //               title: 'wechatMessage',
    //               to: {name: 'apps-orders-wechat-message', params: {id: 21}},
    //           },
    //       ],
    //   },
    {
        title: 'ComplainManager',
        icon: mdiFaceAgent,
        children: [
            {
                title: 'complainAll',
                to: { name: 'apps-orders-complain-list', params: { id: 21 } },
            },
        ],
    },
]
