import { mdiFileCog } from '@mdi/js'
import { mdiSecurity } from '@mdi/js'

export default [
    {
        subheader: 'system',
    },
    {
        title: 'SystemManager',
        icon: mdiSecurity,
        children: [
            {
                title: 'systemUser',
                to: { name: 'apps-system-user', params: { id: 21 } },
            },
            {
                title: 'systemRole',
                to: { name: 'apps-system-role', params: { id: 21 } },
            },
            {
                title: 'systemPrivilege',
                to: { name: 'apps-system-privilege', params: { id: 21 } },
            },
        ],
    },
    {
        title: 'SystemSetting',
        icon: mdiFileCog,
        children: [
            {
                title: 'SystemSettingParam',
                to: { name: 'apps-system-setting', params: { id: 21 } },
            },
        ],
    },
]
