import mock from '@/@fake-db/mock'

const previousDay = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
const dayBeforePreviousDay = new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 2)

/* eslint-disable global-require */
const data = {
    profileUser: {
        id: 11,
        avatar: require('@/assets/images/avatars/1.png'),
        fullName: 'anxpp',
        role: 'admin',
        about: 'anxpp.com',
        status: 'online',
        settings: { isTwoStepAuthVerificationEnabled: true, isNotificationsOn: false },
    },
    contacts: [
        { id: 1, fullName: '聪聪', role: 'text-davinci-003', about: '更加智能的聊天机器人，思维很发散，更强的创造力,可能会出现答非所问', avatar: require('@/assets/images/avatars/5.png'), status: 'offline' },
        { id: 2, fullName: '笨笨', role: 'text-davinci-003', about: '老老实实的聊天机器人', avatar: require('@/assets/images/avatars/2.png'), status: 'busy' },
        { id: 3, fullName: '怪怪', role: '暂未开放', about: '暂未开放', avatar: require('@/assets/images/avatars/7.png'), status: 'busy' },
        { id: 4, fullName: '瑟瑟', role: '暂未开放', about: '暂未开放', avatar: require('@/assets/images/avatars/8.png'), status: 'online' },
    ],
    chats: [
        {
            id: 1,
            userId: 1,
            unseenMsgs: 1,
            chat: [
                {
                    message: '你是聪聪，思维很发散，更强的创造力,可能会出现答非所问',
                    time: new Date(),
                    senderId: 11,
                    feedback: { isSent: false, isDelivered: false, isSeen: false },
                },
            ],
        },
        {
            id: 2,
            userId: 2,
            unseenMsgs: 1,
            chat: [
                {
                    message: '你是笨笨，会老老实实的回答我问题',
                    time: previousDay,
                    senderId: 1,
                    feedback: { isSent: false, isDelivered: true, isSeen: true },
                },
            ],
        },
    ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Chats Contacts and Contacts
// ------------------------------------------------
mock.onGet('/apps/chat/chats-and-contacts').reply(() => {
    const chatsContacts = data.chats
        .map(chat => {
            const contact = data.contacts.find(c => c.id === chat.userId)
            contact.chat = { id: chat.id, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }
            return contact
        }).reverse()
    const profileUserData = {
        id: data.profileUser.id,
        avatar: data.profileUser.avatar,
        fullName: data.profileUser.fullName,
        status: data.profileUser.status,
    }
    return [200, { chatsContacts, contacts: data.contacts, profileUser: profileUserData }]
})

// ------------------------------------------------
// GET: Return Single Chat
// ------------------------------------------------
mock.onGet('/apps/chat/users/profile-user').reply(() => [200, data.profileUser])

// ------------------------------------------------
// GET: Return Single Chat
// ------------------------------------------------
mock.onGet(/\/apps\/chat\/chats\/\d+/).reply(config => {
    // Get event id from URL
    let userId = config.url.substring(config.url.lastIndexOf('/') + 1)
    // Convert Id to number
    userId = Number(userId)
    const chat = data.chats.find(c => c.userId === userId)
    if (chat) chat.unseenMsgs = 0
    return [200, { chat, contact: data.contacts.find(c => c.id === userId) }]
})

// ------------------------------------------------
// POST: Add new chat message
// ------------------------------------------------
mock.onPost(/\/apps\/chat\/chats\/\d+/).reply(config => {
    // Get user id from URL
    const contactId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))
    // Get event from post data
    const { message, senderId } = JSON.parse(config.data)
    let activeChat = data.chats.find(chat => chat.userId === contactId)
    const newMessageData = {
        message,
        time: new Date(),
        senderId,
        feedback: {
            isSent: true,
            isDelivered: false,
            isSeen: false,
        },
    }
    // If there's new chat for user create one
    let isNewChat = false
    if (activeChat === undefined) {
        isNewChat = true
        const { length } = data.chats
        const lastId = data.chats[length - 1].id
        data.chats.push({
            id: lastId + 1,
            userId: contactId,
            unseenMsgs: 0,
            chat: [],
        })
        activeChat = data.chats[data.chats.length - 1]
    }
    activeChat.chat.push(newMessageData)
    const response = { newMessageData }
    if (isNewChat) response.chat = activeChat
    return [201, response]
})
