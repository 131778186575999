const orders = [
    
    // 订单信息
    {
        path: '/apps/orders/order/all',
        name: 'apps-orders-order-all',
        component: () => import('@/views/apps/orders/order/order-all/AllOrder.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/apps/orders/order/wait',
        name: 'apps-orders-order-wait',
        component: () => import('@/views/apps/orders/order/order-wait/WaitOrder.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/apps/orders/order/allocated',
        name: 'apps-orders-order-allocated',
        component: () => import('@/views/apps/orders/order/order-allocated/AllocatedOrder.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/apps/orders/order/timeout',
        name: 'apps-orders-order-timeout',
        component: () => import('@/views/apps/orders/order/order-overdue/OverdueOrder.vue'),
        meta: {
            layout: 'content',
        },
    },
    
    // 微信相关
    {
        path: '/apps/orders/wechat/message',
        name: 'apps-orders-wechat-message',
        component: () => import('@/views/apps/orders/complain/complain-list/Complain.vue'),
        meta: {
            layout: 'content',
        },
    },
    
    // 投诉建议
    {
        path: '/apps/orders/complain/list',
        name: 'apps-orders-complain-list',
        component: () => import('@/views/apps/orders/complain/complain-list/Complain.vue'),
        meta: {
            layout: 'content',
        },
    },

]

export default orders
