import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import snackbarPlugin from '@/plugins/snackbar'
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'

Vue.config.productionTip = false
Vue.prototype.$tinymce = tinymce
Vue.prototype.$Map = window.TMap
Vue.prototype.$Location = new window.qq.maps.Geolocation('R6YBZ-KVPKQ-PSX5J-4KJNP-VRPGQ-KZBK7', '腾讯地图组件')
Vue.use(VueTinymce)
Vue.use(snackbarPlugin, { store });

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
